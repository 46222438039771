import { render, staticRenderFns } from "./PortalUsers.vue?vue&type=template&id=6061b084&scoped=true&"
import script from "./PortalUsers.vue?vue&type=script&lang=js&"
export * from "./PortalUsers.vue?vue&type=script&lang=js&"
import style0 from "./PortalUsers.vue?vue&type=style&index=0&id=6061b084&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6061b084",
  null
  
)

export default component.exports